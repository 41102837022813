.root {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: end;
  justify-content: center;
  pointer-events: none;
}

.root > * {
  pointer-events: auto;
}
