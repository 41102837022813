.slide {
  transition: opacity 350ms ease-in-out;
}

.slideHidden {
  opacity: 0;
}

.slideVisible {
  opacity: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
