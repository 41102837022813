.carousel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.carousel__slide-focus-ring {
  outline: none;
}

.carousel__dot-group {
  --dot-size: 8px;

  flex: 1;
  display: flex;
  background: rgba(255 255 255 / 0%);
  border-radius: calc(2 * var(--dot-size));
}

.carousel__dot {
  flex: 1;
  display: flex;
  margin-top: calc(-4 * var(--dot-size));
  margin-bottom: calc(-4 * var(--dot-size));
  padding: calc(4 * var(--dot-size)) calc(var(--dot-size) / 2);
  border: none;
  background: none;
  border-radius: var(--dot-size);
}

.carousel__dot:active {
  background: none;
  position: relative;
}

.carousel__dot span {
  flex: 1;
  width: calc(var(--dot-size));
  height: var(--dot-size);
  border-radius: var(--dot-size);
  border: none;
  transition: background-color 150ms ease-in-out;
}

.carousel__dot:disabled span {
  cursor: initial;
  background-color: rgba(0, 167, 247, 1);
}

.carousel__dot:not(:disabled) span {
  background-color: rgba(255 255 255 / 10%);
}

.carousel__dot:not(:disabled):hover span {
  background-color: rgba(255 255 255 / 60%);
}

.carousel__dot:not(:disabled):active span {
  background-color: rgba(255 255 255 / 80%);
}

.carousel__buttons {
  --button-size: 36px;
}

.carousel__back-button,
.carousel__next-button {
  height: var(--button-size);
  padding: calc(var(--button-size) / 2);
  border-radius: calc(2 * var(--button-size));
  border: none;
  transition: background-color 150ms ease-in-out;
  display: flex;
  align-items: center;
  color: #000c3f;
  font-size: 15px;
}

.carousel__back-button:disabled,
.carousel__next-button:disabled {
  cursor: initial;
  background-color: rgba(255 255 255 / 20%);
  visibility: hidden;
}

.carousel__back-button:not(:disabled),
.carousel__next-button:not(:disabled) {
  background-color: rgba(255 255 255 / 80%);
}

.carousel__back-button:not(:disabled):hover,
.carousel__next-button:not(:disabled):hover {
  background-color: rgba(255 255 255 / 90%);
}

.carousel__back-button:not(:disabled):active,
.carousel__next-button:not(:disabled):active {
  background-color: rgba(255 255 255 / 100%);
}
